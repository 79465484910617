import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Tooltip } from "antd";
import "antd/dist/antd.css";

const Footer = () => {
  const iconsStyle = {
    display: "flex",
    // justifyContent: "space-evenly",
    width: "100px",
  };

  return (
    <footer>
      <span className="footerCopyrights">Felipe Smaniotto © 2021</span>
      <span className="footerCopyrights">
        <Tooltip title="hi@pifelipes.com">
          <a href="mailto:hi@pifelipes.com" target="_blank" rel="noreferrer">
            <MdEmail className="footerIcon" />
          </a>
        </Tooltip>
        <Tooltip title="Linkedin: Felipe Smaniotto">
          <a
            href="https://www.linkedin.com/in/felipe-smaniotto-89ab177a/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="footerIcon" />
          </a>
        </Tooltip>
        <Tooltip title="Github: @pifelipes">
          <a
            href="https://github.com/pifelipes"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub className="footerIcon" />
          </a>
        </Tooltip>
      </span>
    </footer>
  );
};

export default Footer;
